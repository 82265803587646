html {
  height: 100%;
  background-color: transparent;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  height: 100%;
  background-color: #EBEBEB;
  font-family: Arial, Helvetica, sans-serif;
}

body.qr-scanner {
  background-color: transparent;
}

.qr-scanner-target {
  width: 100vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-scanner-target div {
  width: 200px;
  height: 200px;
  background-color: transparent;
  border: 2px dotted #efefef;
  border-radius: 5px;
}

.footer {
  bottom: 0;
  text-align: center;
  /*background-color: #ffffff;*/
}

h1, h2 {
  word-wrap: break-word;
  hyphens: auto;
  margin-bottom: 1.2rem;
}

h1, .h1 {
  font-size: 1.9rem;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, .9);
}

.navbar-brand {
  img {
    max-height: 30px;
    width: auto;
  }
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.6; /* Firefox */
}

.card-box {
  padding: 20px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  background-clip: padding-box;
  margin-bottom: 20px;
  background-color: #ffffff;
}

.validation-response {
  display: none;
  width: calc(100% - 12px);
  left: 6px;
  top: 113px;
  position: fixed;

  color: white;
  padding: 10px;
  border-radius: 0.3rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: calc(100% - 119px);
  transition: visibility 0s, opacity 0.5s linear;
  text-align: center;
  /*transform: translate(0, -50px);*/
}

.navbar {
  z-index: 2;
}

.nav .nav-link {
  z-index: 2;
}

.navbar-collapse {
  padding-top: 1rem;
}

.column-scanner-container {
  .navbar {
    background: none !important;

    .language-btn {
      display: none;
    }

    .navbar-toggler {
      color: transparent;
      border: none;

      .navbar-toggler-icon {
        background-image: none;
      }
    }
  }

  .config-container, .select-event-title {
    color: #EEEEEE;
  }

  .tab-pane-3 {
    #lazer_scanner_start_text {
      font-size: 5rem;

      @media (max-width: 992px) {
        font-size: 2rem;
      }
      color: #EEEEEE;
      margin-top: 6%;
    }

    .material-icons {
      font-size: 10rem !important;
      @media (max-width: 992px) {
        font-size: 6rem !important;
      }
    }

    .validation-response {
      top: 6px;
      height: calc(100% - 12px);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 5rem;
      @media (max-width: 992px) {
        font-size: 2rem;
      }
    }
  }
}

.error-alert {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 10px;
  transform: translate(calc(50vw - 50% - 10px), calc(50vh - 50% - 10px));
  background: #9e0039;
  margin-right: 10px;
  color: #FFFFFF;
  z-index: 10000;
  border-radius: 4px;
  padding: 15px;
  transition: visibility 0s, opacity 0.5s linear;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  font-size: 1.3rem;
  display: none;
}