.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

html {
  .form-check-input {
    margin-top: 0.1rem;
    width: 1.3rem;
    height: 1.3rem;
  }

  .form-check-label {
    margin-bottom: 0.8rem;
    margin-left: 0.7rem;
  }
}